import React from "react";
import Layout from "../components/layout/Layout";
import SEO from "../components/seo/Seo";
import { Container } from "react-bootstrap";

const TermsOfService = () => {
  return (
    <Layout opaqueHeader="true">
      <SEO title="Terms of Service" />
      <div className="tos-page">
        <Container className="container">
          <h1>TERMS OF SERVICE</h1>
          If you do not agree with these Terms of Service, do not complete the
          registration process. These Terms of Service, including Legal Notices
          and Privacy Policy, contain the entire terms and conditions that apply
          to your use of TRIdigital LLC's (hereinafter referred to as
          "TRIdigital") online platforms and services, (hereinafter referred to
          as "Services"), and its online properties and websites (collectively
          referred to herein as "Sites"). These Terms of Service may be modified
          from time to time without notice.
          <strong>
            These Terms of Service supersede any prior Terms of Service. The
            current version applies to your use of the Sites and Services both
            now and previously.
          </strong>
          <h2>
            <strong>License</strong>
          </h2>
          <ol>
            <li>
              TRIdigital provides a limited, nontransferable, nonexclusive,
              revocable license to create a Master Account and login credentials
              for our services upon initial registration to the Sites and
              Services, with the ability to invite anyone within your
              organization to create Sub Accounts under your Master Account with
              their own individual login credentials, thus allowing anyone
              within your organization access to the Sites' content and Services
              available to your subscription level at any time. You are
              responsible for maintaining the confidentiality of these
              credentials, and for any and all activities that occur under them.
              You agree to notify TRIdigital immediately of any unauthorized use
              of the login credentials or any other breach of security you
              become aware of by opening a support ticket at
              support@tridigitalmarketing.com.
            </li>
            <li>
              TRIdigital grants subscribers use of the content on its Sites for
              lawful purposes. You agree to comply with all local, state,
              national and international laws, including copyright laws. The use
              of the TRIdigital Sites or Services for any criminal or illegal
              activities is strictly forbidden, and TRIdigital will take legal
              action against violations of this policy.
            </li>
            <li>
              By subscribing, you hereby agree to access and use the Services
              for their intended purposes, and not for any other purpose or to
              alter the content and/or Services in any way, including, but not
              limited to, removing or deleting any copyright notices. You agree
              to comply with all local, state, national and international laws,
              including copyright laws.
            </li>
            <li>
              This license does not include the right to collect or use the
              information contained on the TRIdigital Sites or Services for
              purposes prohibited by TRIdigital, to compete with TRIdigital or
              create derivative works based on any and all content obtained
              through the Services.
            </li>
            <li>
              You may not license to any 3rd party any content, information,
              documents, graphics or other materials or content obtained through
              your use of the Services, in whole or in part, for commercial or
              non-commercial purposes outside of your organization.
            </li>
            <li>
              Your subscription to use TRIdigital's Sites and Services is
              limited to you and/or your organization's sole use only. Resale or
              use of TRIdigital's Sites and Services to seek financial gain from
              providing TRIdigital's Sites and Services to any third party,
              without the express prior written consent of TRIdigital, is
              strictly prohibited. By using TRIdigital's Sites and Services, you
              acknowledge and agree that portions of the content may be provided
              to you by third-party licensors, and you hereby agree to adhere to
              and comply with any such third-party's license(s) and to refrain
              from violating any such licensor's intellectual property rights.
            </li>
          </ol>
          <h2>Content</h2>
          <ol>
            <li>
              At any time, TRIdigital may add or enable features such as forums,
              blogs, rating systems or others that may enable subscribers to
              post content to its Sites. When you post to the Sites, you
              understand and agree that this may be viewed and used by other
              visitors to the Sites. TRIdigital does not condone, and will not
              allow the publishing of explicit, unlawful or offensive content.
            </li>
            <li>
              By subscribing, you hereby certify that any content you post to
              the Sites is original and that no other person or entity holds
              copyright to that content (even when content is posted by others
              who have Sub Accounts under your Master Account). You further
              agree to grant TRIdigital a non-exclusive, perpetual, irrevocable,
              unrestricted, transferable, fully sub-licensable, worldwide,
              royalty-free license to use, distribute, display, reproduce,
              perform, modify, adapt, publish, translate and create derivative
              works from any content you post in any form, media or technology,
              whether now-known or hereafter developed.
            </li>
          </ol>
          <h2>Third-Party Advertisements</h2>
          <ol>
            <li>
              You acknowledge and agree that TRIdigital may feature
              advertisements from TRIdigital or third parties. TRIdigital's
              disclosure of information for third party advertising is addressed
              in our Privacy Policy here:{" "}
              <a href="/privacy-policy/">
                https://tridigitalmarketing.com/privacy-policy.
              </a>
            </li>
          </ol>
          <h2>Links to Third Party Sites</h2>
          <ol>
            <li>
              TRIdigital's Sites and Services may provide links to other Web
              sites or resources over which TRIdigital does not have control
              ("External Web sites"). Such links do not constitute an
              endorsement by TRIdigital of those External Web sites. You
              acknowledge that TRIdigital is providing these links to you only
              as a convenience, and further agree that TRIdigital is not
              responsible for the content of such External Web sites. Your use
              of External Web sites is subject to the terms of use and privacy
              policies located on the linked to External Web sites.
            </li>
          </ol>
          <h2>TRIdigital Rights</h2>
          <ol>
            <li>
              As a Subscriber to TRIdigital’s Sites and Services, you grant
              TRIdigital a limited, non-exclusive, royalty-free, worldwide
              license to develop marketing services, use your logo and create
              marketing and web assets for your use, so long as your
              subscription is current and in good standing.
            </li>
            <li>
              You acknowledge and agree that TRIdigital reserves all
              intellectual and look and feel and copyrights to the layout,
              design, concept, content and organization of the TRIdigital Sites
              and Services.
            </li>
            <li>
              You acknowledge and agree that TRIdigital is the sole owner of all
              right, title and interest in the Services, the Sites, and all look
              and feel and content copyrights, patents, trademarks and other
              intellectual property rights therein.
            </li>
            <li>
              You acknowledge and agree that any use of the TRIdigital Sites,
              Services, technology, content, and/or look and feel and content
              copyrights, patents, trademarks or other intellectual property
              rights, other than as described in this Agreement is strictly
              prohibited.
            </li>
            <li>
              You acknowledge and agree that the distribution of any TRIdigital
              Services, technology, look and feel or content copyrights, or
              other intellectual property obtained through the TRIdigital Sites
              to any third party not affiliated with your organization or
              company without the express written consent of TRIdigital is
              strictly prohibited. In addition, you agree not to copy the
              layout, design, concept and organization of the Sites or Services
              at any time.
            </li>
            <li>
              You agree not to use any of TRIdigital's trade names, branding,
              service marks or any other trade insignia without TRIdigital's
              prior written consent.
            </li>
            <li>
              You acknowledge and agree that TRIdigital, in its sole discretion,
              has the right to suspend or terminate your account and refuse any
              and all current or future use of TRIdigital Sites or Services for
              any reason at any time. Such termination will result in the
              deactivation or deletion of your Master Account and all Sub
              Accounts or your access to same, and the forfeiture and
              relinquishment of all content or data in them. TRIdigital reserves
              the right to refuse service to anyone for any reason at any time.
            </li>
          </ol>
          <h2>No Warranty</h2>
          <ol>
            <li>
              Access to TRIdigital Sites and Services is provided “as-is”, “with
              all faults” and “as available”.
            </li>
            <li>
              TRIdigital does not guarantee the accuracy or timeliness of
              information available from the Sites and Services.
            </li>
            <li>
              TRIdigital makes no warranty that the Sites and Services will meet
              your requirements.
            </li>
            <li>
              No advice or information, whether oral or written, obtained by you
              through the Sites or Services shall create any warranty
              whatsoever, whether implied or otherwise.
            </li>
            <li>
              Technical support is only available by opening a ticket at{" "}
              <a href="mailto:support@tridigitalmarketing.com">
                support@tridigitalmarketing.com.
              </a>
            </li>
          </ol>
          <h2>Limitation of Liability</h2>
          <ol>
            <li>
              You agree that in no event shall TRIdigital, its officers,
              directors, employees, vendors, advertisers or agents be liable to
              you in any manner whatsoever for indirect, special, incidental,
              punitive or consequential damages arising out of your use of the
              Sites or Services provided by TRIdigital, including but not
              limited to loss of data, profits or revenue.
            </li>
            <li>
              Your sole and exclusive remedy with respect to the use of the
              Sites or any Services provided by TRIdigital shall be cancellation
              of your subscription to the Sites and Services.
            </li>
          </ol>
          <h2>Indemnification</h2>
          <ol>
            <li>
              As a Subscriber, you agree to indemnify and hold harmless
              TRIdigital, its officers, directors, employees, vendors,
              advertisers and agents against any and all liability, claims,
              suits, losses, costs and legal fees caused by, arising out of, or
              resulting from any breach of your covenants under this Agreement
              or your use of the Sites or Services.
            </li>
          </ol>
          <h2>Payment Terms</h2>
          <ol>
            <li>
              A Paid Subscription is billed in advance on a monthly basis on its
              anniversary date for the length of your subscription and is
              non-refundable. There will be no refunds or credits for partial
              months of service, or for months unused with an active account. In
              order to treat everyone equally, no exceptions will be made.
            </li>
            <li>
              All Subscription fees are exclusive of any applicable taxes,
              levies, or duties you or your organization may be liable for, and
              you are solely responsible for payment of all such taxes, levies,
              or duties.
            </li>
          </ol>
          <h2>Termination</h2>
          <ol>
            <li>
              Your subscription will renew automatically at the end of your
              term. If you do not wish to renew your subscription, you may send
              a written notice thirty (30) days prior to the end of your term.
              Should you choose not to renew your subscription:
              <ol>
                <li>
                  You and your staff must immediately cease usage of all
                  resources obtained from TRIdigital's Sites and Services
                  through your Paid Subscription. This includes all files, tools
                  and assets downloaded by you or your staff via your Master
                  Account and any Sub Accounts during your active subscription.
                </li>
                <li>
                  You and your staff will no longer have access to the Sites and
                  Services, nor any content that you may have uploaded to the
                  Sites
                </li>
              </ol>
            </li>
            <li>
              <p>
                This Agreement may be terminated by you upon thirty (30) days
                written notice without penalty if TRIdigital:
              </p>
              <p>
                1. Fails to fulfill in any material respect its obligations
                under this Agreement and does not cure such failure within
                thirty (30)
              </p>
              <p>    days of receipt of such written notice.</p>
              <p>
                2. Breaches any material term or condition of this Agreement and
                fails to remedy such breach within thirty (30) days of
              </p>
              <p>     receipt of such written notice.</p>
              <p>
                3. Terminates or suspends its business operations, unless it is
                succeeded by a permitted assignee.
              </p>
            </li>
          </ol>
          <strong>
            PLEASE NOTE THAT YOU AND YOU ALONE ARE SOLELY RESPONSIBLE FOR
            PROPERLY RENEWING YOUR SUBSCRIPTION. WE WILL NOT BE HELD LIABLE FOR
            YOUR FAILURE TO RENEW YOUR SUBSCRIPTION AND ITS CONSEQUENCES.
          </strong>
          <h2>Severability</h2>
          <ol>
            <li>
              The failure of TRIdigital to exercise or enforce any right or
              provision of these Terms of Service shall not constitute a waiver
              of such right or provision. These Terms of Service constitute the
              entire agreement between you and TRIdigital and govern your use of
              the Sites and Services, superseding any prior agreements between
              you and TRIdigital (including, but not limited to, any prior
              versions of the Terms of Service).
            </li>
          </ol>
          <h2>Applicable Law and Jurisdiction</h2>
          <ol>
            <li>
              These Terms of Service shall be governed by and interpreted in
              accordance with the laws of the State of Texas, the United States
              and the personal jurisdiction and venue of the Superior Court of
              Dallas County and the United States District Court for the Western
              District of Texas.
            </li>
          </ol>
          <h2>Notices</h2>
          <ol>
            <li>
              By subscribing, you consent to receive electronic communications
              from TRIdigital and its partners and affiliates according to our{" "}
              <a href="/privacy-policy/">Privacy Policy.</a> These electronic
              communications may include notices about applicable fees and
              charges, transactional information and other information
              concerning or related to our Sites and/or Services. These
              electronic communications are part of your relationship with
              TRIdigital and you receive them as part of your use of
              TRIdigital's Sites and Services. You agree that any notices,
              agreements, disclosures or other communications that we send you
              electronically will satisfy any legal communication requirements,
              including that such communications be in writing.
            </li>
            <li>
              Except as explicitly stated otherwise, all notices permitted or
              required under these Terms of Service shall be by email, to us at{" "}
              <a href="mailto:info@tridigitalmarketing.com">
                info@tridigitalmarketing.com
              </a>{" "}
              and to you at the email address you provide TRIdigital during the
              subscription process, or to such other address as either of us
              shall specify in writing as our addresses for notice. Notice shall
              be deemed given 24 hours after email is sent, unless the sending
              party is notified that the email address is invalid. Evidence of
              successful transmission shall be retained.
            </li>
            <li>
              TRIdigital may modify these Terms of Service at its sole
              discretion by posting modifications on its Sites. Your continued
              use of the Sites and Services after notices of modification
              constitutes your acceptance of the modifications. If you do not
              agree to the modifications, please contact us by email at{" "}
              <a href="mailto:info@tridigitalmarketing.com">
                info@tridigitalmarketing.com
              </a>
            </li>
          </ol>
          Entire Agreement
          <ol>
            <li>
              These terms of service constitute the entire agreement and
              understanding between us and supersedes all prior agreements and
              understandings.
            </li>
          </ol>
          <h1>TRIDIGITAL MARKETING WARM UP STATEMENT OF WORK</h1>
          <h2>MARKETING WARM UP - TESTIN' IT</h2>
          <strong>3 Month Plan - $1,200 per month</strong>
          <ul>
            <li>
              Full GlassHive setup, training & support with an assigned Product
              Coach
              <ul>
                <li>
                  You will be given a 12-month license of GlassHive at no
                  additional cost for your organization with 2 users. Your
                  Product Coach will assist you in getting it fully set up and
                  integrated then coach you on best practices.
                </li>
              </ul>
            </li>
            <li>
              Initial Sales and Marketing Needs Analysis
              <ul>
                <li>
                  You will be given an initial sales and marketing needs
                  analysis from your assigned Product Coach. This will determine
                  the baseline and goals for your 3 month marketing plan.
                </li>
              </ul>
            </li>
            <li>
              2 Graphical Campaigns per month
              <ul>
                <li>
                  Mobile responsive HTML emails matching your corporate
                  branding. Your emails will be created and delivered via
                  GlassHive
                </li>
              </ul>
            </li>
            <li>
              2 Personal Text Only Marketing Campaigns per month (PMP)
              <ul>
                <li>
                  Well written email follow up campaigns created and saved into
                  GlassHive for unlimited use from your sales team. These emails
                  are designed to follow up with leads.
                </li>
              </ul>
            </li>
            <li>
              Up to 3 Branded Collateral Assets
              <ul>
                <li>
                  Designed PDFs on selected product or services you deliver.
                  These are designed to generate curiosity in your target
                  audience. These collateral assets will be designed to match
                  your corporate branding then will be uploaded to GlassHive's
                  marketing collateral library
                </li>
              </ul>
            </li>
            <li>
              Up to 3 Branded Landing Pages
              <ul>
                <li>
                  Responsive HTML web pages. Your landing pages can feature
                  solution and service explainer pages, event promotion pages,
                  or pages offering a video or collateral asset download along
                  with a strong call to action. Landing Page development
                  incorporates copy and visual design.
                </li>
              </ul>
            </li>
            <li>
              Up to 3 Solution Marketing Videos
              <ul>
                <li>
                  Explainer videos of select products and services. These are
                  designed to entice your target audience to contact you to
                  learn more about your services.
                </li>
              </ul>
            </li>
            <li>
              1 Quarterly Consultation Calls
              <ul>
                <li>
                  In-depth analysis conducted by your Product Coach where we
                  review the previous marketing activities, trends and analytics
                  to inform our ongoing marketing strategy.
                </li>
              </ul>
            </li>
            <li>
              White Glove Marketing Execution
              <ul>
                <li>
                  We will advise you on best practices for the execution of
                  proper use of GlassHive and coach you on best practices for
                  sales follow up. We will also ensure your marketing is sent
                  out in a timely manner.
                </li>
              </ul>
            </li>
          </ul>
          <h2>MARKETING WARM UP - FEELIN' IT</h2>
          <strong>6 Month Plan - $1,000 per month</strong>
          <ul>
            <li>
              Full GlassHive setup, training & support with an assigned Product
              Coach
              <ul>
                <li>
                  You will be given a 12-month license of GlassHive at no
                  additional cost for your organization with 2 users. Your
                  Product Coach will assist you in getting it fully set up and
                  integrated then coach you on best practices.
                </li>
              </ul>
            </li>
            <li>
              Initial Sales and Marketing Needs Analysis
              <ul>
                <li>
                  You will be given an initial sales and marketing needs
                  analysis from your assigned Product Coach. This will determine
                  the baseline and goals for your 3 month marketing plan.
                </li>
              </ul>
            </li>
            <li>
              2 Graphical Campaigns per month
              <ul>
                <li>
                  Mobile responsive HTML emails matching your corporate
                  branding. Your emails will be created and delivered via
                  GlassHive
                </li>
              </ul>
            </li>
            <li>
              2 Personal Text Only Marketing Campaigns per month (PMP)
              <ul>
                <li>
                  Well written email follow up campaigns created and saved into
                  GlassHive for unlimited use from your sales team. These emails
                  are designed to follow up with leads.
                </li>
              </ul>
            </li>
            <li>
              Up to 6 Branded Collateral Assets
              <ul>
                <li>
                  Designed PDFs on selected product or services you deliver.
                  These are designed to generate curiosity in your target
                  audience. These collateral assets will be designed to match
                  your corporate branding then will be uploaded to GlassHive's
                  marketing collateral library
                </li>
              </ul>
            </li>
            <li>
              Up to 6 Branded Landing Pages
              <ul>
                <li>
                  Responsive HTML web pages. Your landing pages can feature
                  solution and service explainer pages, event promotion pages,
                  or pages offering a video or collateral asset download along
                  with a strong call to action. Landing Page development
                  incorporates copy and visual design.
                </li>
              </ul>
            </li>
            <li>
              Up to 6 Solution Marketing Videos
              <ul>
                <li>
                  Explainer videos of select products and services. These are
                  designed to entice your target audience to contact you to
                  learn more about your services.
                </li>
              </ul>
            </li>
            <li>
              2 Quarterly Consultation Calls
              <ul>
                <li>
                  In-depth analysis conducted by your Product Coach where we
                  review the previous marketing activities, trends and analytics
                  to inform our ongoing marketing strategy.
                </li>
              </ul>
            </li>
            <li>
              White Glove Marketing Execution
              <ul>
                <li>
                  We will advise you on best practices for the execution of
                  proper use of GlassHive and coach you on best practices for
                  sales follow up. We will also ensure your marketing is sent
                  out in a timely manner.
                </li>
              </ul>
            </li>
          </ul>
          <h2>MARKETING WARM UP - LOVIN' IT</h2>
          <strong>12 Month Plan - $750 per month</strong>
          <ul>
            <li>
              Full GlassHive setup, training & support with an assigned Product
              Coach
              <ul>
                <li>
                  You will be given a 12-month license of GlassHive at no
                  additional cost for your organization with 2 users. Your
                  Product Coach will assist you in getting it fully set up and
                  integrated then coach you on best practices.
                </li>
              </ul>
            </li>
            <li>
              Initial Sales and Marketing Needs Analysis
              <ul>
                <li>
                  You will be given an initial sales and marketing needs
                  analysis from your assigned Product Coach. This will determine
                  the baseline and goals for your 3 month marketing plan.
                </li>
              </ul>
            </li>
            <li>
              2 Graphical Campaigns per month
              <ul>
                <li>
                  Mobile responsive HTML emails matching your corporate
                  branding. Your emails will be created and delivered via
                  GlassHive
                </li>
              </ul>
            </li>
            <li>
              2 Personal Text Only Marketing Campaigns per month (PMP)
              <ul>
                <li>
                  Well written email follow up campaigns created and saved into
                  GlassHive for unlimited use from your sales team. These emails
                  are designed to follow up with leads.
                </li>
              </ul>
            </li>
            <li>
              Up to 12 Branded Collateral Assets
              <ul>
                <li>
                  Designed PDFs on selected product or services you deliver.
                  These are designed to generate curiosity in your target
                  audience. These collateral assets will be designed to match
                  your corporate branding then will be uploaded to GlassHive's
                  marketing collateral library
                </li>
              </ul>
            </li>
            <li>
              Up to 12 Branded Landing Pages
              <ul>
                <li>
                  Responsive HTML web pages. Your landing pages can feature
                  solution and service explainer pages, event promotion pages,
                  or pages offering a video or collateral asset download along
                  with a strong call to action. Landing Page development
                  incorporates copy and visual design.
                </li>
              </ul>
            </li>
            <li>
              Up to 12 Solution Marketing Videos
              <ul>
                <li>
                  Explainer videos of select products and services. These are
                  designed to entice your target audience to contact you to
                  learn more about your services.
                </li>
              </ul>
            </li>
            <li>
              4 Quarterly Consultation Calls
              <ul>
                <li>
                  In-depth analysis conducted by your Product Coach where we
                  review the previous marketing activities, trends and analytics
                  to inform our ongoing marketing strategy.
                </li>
              </ul>
            </li>
            <li>
              White Glove Marketing Execution
              <ul>
                <li>
                  We will advise you on best practices for the execution of
                  proper use of GlassHive and coach you on best practices for
                  sales follow up. We will also ensure your marketing is sent
                  out in a timely manner.
                </li>
              </ul>
            </li>
            <li>Journey Automation Setup</li>
            <li>Preferred Partner Discount on Projects</li>
          </ul>
          <h2>Warmup Policies</h2>
          <h3>Choosing of style and of solutions.</h3>
          <span>
            A client may complete the Warmup questionnaire and select what
            solutions they would like to market and the style of their assets
            from the options TRIdigital provides. The client will have two weeks
            from the date of signing Terms of Services to make their choice. If
            the client does not choose within two weeks, TRIdigital will make
            the selections for the client. 
          </span>
          <span>
            If the client misses the two-week window, the client may make the
            selection of style and solutions afterward and will be applied to
            future deliverables. However, the change will not take place on any
            previously delivered items or those that are currently in
            production.
          </span>
          <h3>Go live timeline</h3>
          <span>
            TRIdigital will go live two weeks from the date the client has
            provided all the information and answered all the questions needed
            to begin the creation of deliverables within the service.
          </span>
          <h3>Email Marketing</h3>
          <span>
            TRIdigital must receive a list in order to market. If no list is
            received, TRIdigital will build out all assets but will not be able
            to send them out. Deliverables will still be created for the length
            of the agreement.
          </span>
          <h3>Edits</h3>
          <span>
            The Warmup is a templated service built to generate marketing
            prospects by utilizing content that TRIdigital has approved. No
            edits, outside of the ones listed below, can be made to the content
            or design of these items.
          </span>
          <span>
            The following are edits the client can make with no penalty:
          </span>
          <ul>
            <li>
              <span>- Phone number</span>
            </li>
            <li>
              <span>- Company name</span>
            </li>
            <li>
              <span>- Website URL</span>
            </li>
            <li>
              <span>- Employee names</span>
            </li>
            <li>
              <span>- Address</span>
            </li>
          </ul>
        </Container>
      </div>
    </Layout>
  );
};

export default TermsOfService;
